//import { call } from 'redux-saga/effects';
//import { Observable } from 'zen-observable-ts';
//import { API, graphqlOperation } from 'aws-amplify';

//export function* SubscribeToUpdates<T>(
//    subscriptionQuery: any,
//    variables: any | null,  // Add variables parameter here
//    onNext: (data: T) => void,
//    onError: (error: any) => void
//) {
//    let subscription: ZenObservable.Subscription | null = null;

//    try {
//        // Use `call` to invoke the subscription
//        const observable: Observable<{ value: { data: T } }> = yield call(
//            [API, 'graphql'],
//            variables ? graphqlOperation(subscriptionQuery, variables) : graphqlOperation(subscriptionQuery)
//        );

//        // Subscribe to the updates and store the subscription
//        subscription = observable.subscribe({
//            next: ({ value }) => {
//                const data = value.data;

//                if (data) {
//                    onNext(data);

//                    // Unsubscribe after receiving the first valid data
//                    if (subscription) {
//                        subscription.unsubscribe();
//                        //console.log('Subscription closed after receiving data');
//                    }
//                }
//            },
//            error: (error: any) => {
//                onError(error);

//                // Unsubscribe on error
//                if (subscription) {
//                    subscription.unsubscribe();
//                    //console.log('Subscription closed due to an error');
//                }
//            },
//        });

//        // Keep the saga active until the subscription completes
//        yield new Promise(() => { });  // Prevent saga from completing immediately
//    } catch (err) {
//        console.log('Error subscribing:', err);
//    }
//}
//import { delay, fork, call } from 'redux-saga/effects';
//import { Observable } from 'zen-observable-ts';
//import { API, graphqlOperation } from 'aws-amplify';

//// Retry logic constants
//const MAX_RETRIES = 3;
//const RETRY_DELAY = 2000; // delay in ms

//// Updated subscription function with retry mechanism
//export function* SubscribeToUpdates<T>(
//    subscriptionQuery: any,
//    variables: any | null,
//    onNext: (data: T) => void,
//    onError: (error: any) => void,
//    retries: number = MAX_RETRIES
//) {
//    let attempt = 0;
//    let subscription: ZenObservable.Subscription | null = null;

//    // Wrap the subscription logic in a single loop, but only subscribe once
//    while (attempt <= retries) {
//        try {
//            // Only subscribe once
//            if (!subscription) {
//                const observable: Observable<{ value: { data: T } }> = yield call(
//                    [API, 'graphql'],
//                    variables ? graphqlOperation(subscriptionQuery, variables) : graphqlOperation(subscriptionQuery)
//                );

//                // Subscribe to updates
//                subscription = observable.subscribe({
//                    next: ({ value }) => {
//                        const data = value.data;
//                        if (data) {
//                            onNext(data);
//                        }
//                    },
//                    error: (error: any) => {
//                        console.error('Subscription error:', error);
//                        throw error; // Throw error so retry logic can catch it
//                    },
//                });
//            }

//            // Exit loop once successful subscription is established
//            yield new Promise(() => { }); // Prevent the saga from completing
//        } catch (error) {
//            attempt++;
//            if (attempt > retries) {
//                console.error('Max retries reached. Subscription failed.');
//                onError(error);
//                break; // Exit loop after max retries
//            } else {
//                console.error(`Retrying subscription (attempt ${attempt})...`);
//                yield delay(RETRY_DELAY); // Delay before retrying
//            }
//        }
//    }

//    // Cleanup on exit: unsubscribe if the subscription exists
//    if (subscription) {
//        subscription.unsubscribe();
//    }
//}
import { delay, call } from 'redux-saga/effects';
import { Observable } from 'zen-observable-ts';
import { API, graphqlOperation, Amplify } from 'aws-amplify';

// Retry logic constants
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // delay in ms

export function* SubscribeToUpdates<T>(
    subscriptionQuery: any,
    variables: any | null,
    onNext: (data: T) => void,
    onError: (error: any) => void,
    accessToken: string | undefined, // Add accessToken as a parameter
    retries: number = MAX_RETRIES
) {
    let attempt = 0;
    let subscription: ZenObservable.Subscription | null = null;

    try {
        // Configure API headers
        Amplify.configure({
            API: {
                graphql_headers: async () => {
                    return {
                        Authorization: accessToken, // Pass the token here
                    };
                },
            },
        });

        while (attempt <= retries) {
            try {
                if (!subscription) {
                    const observable: Observable<{ value: { data: T } }> = yield call(
                        [API, 'graphql'],
                        graphqlOperation(subscriptionQuery, variables)
                    );

                    subscription = observable.subscribe({
                        next: ({ value }) => {
                            const data = value.data;
                            if (data) {
                                onNext(data);
                            }
                        },
                        error: (error: any) => {
                            console.error('Subscription error:', error);
                            throw error; // Throw error so retry logic can catch it
                        },
                    });
                }

                // Prevent the saga from completing
                yield new Promise(() => { });
            } catch (error) {
                attempt++;
                if (attempt > retries) {
                    console.error('Max retries reached. Subscription failed.');
                    onError(error);
                    break; // Exit loop after max retries
                } else {
                    console.error(`Retrying subscription (attempt ${attempt})...`);
                    yield delay(RETRY_DELAY); // Delay before retrying
                }
            }
        }
    } catch (error) {
        console.error('Error during subscription setup:', error);
        onError(error);
    } finally {
        // Cleanup on exit: unsubscribe if the subscription exists
        if (subscription) {
            subscription.unsubscribe();
        }
    }
}