import update from 'immutability-helper';
import {
    concat,
    get,
    includes,
    isEqual,
    keyBy,
    merge,
    remove,
    uniqWith,
    values,
} from 'lodash';
import { Reducer } from 'redux';
import {
    DETAILS_TAB,
    TASKS_PAGE,
    TASK_HISTORY_PAGE,
} from '../../config/tableAndPageConstants';
import {
    commonInvoiceListInvoicesSortOptions,
    invoicesStateFilterOptions,
} from '../../constants/invoicesSortAndFilters';
import { paymentScheduleSortByOptions } from '../../constants/paymentPlansSortAndFilters';
import {
    taskHistoryDetailsDeliverySortByOptions,
    taskHistoryItemDetailsNotificationSortOptions,
    taskHistorySortByOptions,
    tasksSortByOptions,
    tasksStatusFilterOptions,
} from '../../constants/tasksSortAndFilters';
import { TasksActionTypes, TasksState } from './types';

// Type-safe initialState!
export const initialState: TasksState = {
    activeTasks: {
        loading: false,
        saveTaskDataLoading: false,
        errorMessages: [],
        data: [],
        pageData: {
            pageSize: TASKS_PAGE.pageSize,
            currentPage: 0,
            hasNextPage: false,
        },
        filters: {},
        taskStatus: get(tasksStatusFilterOptions, `${2}.value`),
        taskTableFilter: undefined,
        sortBy: tasksSortByOptions[1].value,
        actionFilterOptions: {},
        actionFilterOptionsLoading: false,
        sortAscending: true,
        viewingUser: {
            user: undefined,
            loading: false
        },
        activeData: {
            record: {},
            loading: false,
            selectedId: null,
            errorMessages: [],
            invoices: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: DETAILS_TAB.INVOICE_LIST.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortBy: commonInvoiceListInvoicesSortOptions[0].value, //tasksSortByOptions[0].value,
                sortAscending: true,
                invoiceState: get(invoicesStateFilterOptions, `${1}.value`),
            },
            schedule: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: DETAILS_TAB.PAYMENT_PLAN_SCHEDULE_LIST.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortBy: paymentScheduleSortByOptions[0].value, // 2 for Invoice number
                sortAscending: true,
            }
        },
        notifyCustomersLoading: false,
        compactView: false,
        compactViewLoading: false,
        toggledExpandStatusIds: [],
    },
    taskHistory: {
        loading: false,
        errorMessages: [],
        data: [],
        pageData: {
            pageSize: TASK_HISTORY_PAGE.pageSize,
            currentPage: 0,
            hasNextPage: false,
        },
        filters: {},
        taskTableFilter: undefined,
        sortBy: taskHistorySortByOptions[1].value,
        sortAscending: false, // default is descending
        activeData: {
            record: {},
            loading: false,
            selectedId: null,
            errorMessages: [],
            notifications: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: TASK_HISTORY_PAGE.notifications.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortBy: taskHistoryItemDetailsNotificationSortOptions[0].value,
                sortAscending: true,
                activeData: {
                    loading: false,
                    selectedId: null,
                    errorMessages: [],
                    record: {},
                    invoices: {
                        loading: false,
                        errorMessages: [],
                        data: [],
                        pageData: {
                            pageSize: DETAILS_TAB.INVOICE_LIST.pageSize,
                            currentPage: 0,
                            hasNextPage: false,
                        },
                        filters: {},
                        sortBy: commonInvoiceListInvoicesSortOptions[0].value, //tasksSortByOptions[0].value,
                        sortAscending: true,
                        invoiceState: get(
                            invoicesStateFilterOptions,
                            `${1}.value`
                        ),
                    },
                    tasks: {
                        loading: false,
                        errorMessages: [],
                        data: [],
                        pageData: {
                            pageSize:
                                TASK_HISTORY_PAGE.notifications.tasks.pageSize,
                            currentPage: 0,
                            hasNextPage: false,
                        },
                        filters: {},
                        sortBy: '', //tasksSortByOptions[0].value,
                        sortAscending: true,
                    },
                },
            },
            changedTasks: {
                activeData: {
                    loading: false,
                    record: {},
                    errorMessages: [],
                },
                list: {
                    loading: false,
                    errorMessages: [],
                    data: [],
                    pageData: {
                        pageSize: TASK_HISTORY_PAGE.changedTasks.pageSize,
                        currentPage: 0,
                        hasNextPage: false,
                    },
                },
            },
            collections: {
                activeData: {
                    loading: false,
                    record: {},
                    errorMessages: [],
                },
                actionedTasks: {
                    loading: false,
                    errorMessages: [],
                    data: [],
                    pageData: {
                        pageSize: TASK_HISTORY_PAGE.collections.pageSize,
                        currentPage: 0,
                        hasNextPage: false,
                    },
                    activeData: {
                        loading: false,
                        selectedId: null,
                        errorMessages: [],
                        record: {},
                    },
                },
            },
            failedList: {
                loading: false,
                errorMessages: [],
                data: [],
                pageData: {
                    pageSize: TASK_HISTORY_PAGE.failedTasks.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
            },
            detailsDelivery: {
                loading: false,
                pageData: {
                    pageSize: TASK_HISTORY_PAGE.deliveryReport.tasks.pageSize,
                    currentPage: 0,
                    hasNextPage: false,
                },
                filters: {},
                sortAscending: true,
                sortBy: taskHistoryDetailsDeliverySortByOptions[1].value,
                data: [],
                activeData: {
                    loading: false,
                    selectedId: null,
                    errorMessages: [],
                    record: {},
                },
            }
        },
    },
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<TasksState> = (state = initialState, action) => {
    switch (action.type) {
        case TasksActionTypes.GET_TASKS_FOR_ORGANISATION_REQUEST:
        case TasksActionTypes.GET_TASKS_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.GET_TASKS_SUCCESS: {
            let newDataState = [];
            if (action.payload.mergeData === true) {
                newDataState = values(
                    merge(
                        keyBy(state.activeTasks.data, 'Id'),
                        keyBy(action.payload.data, 'Id')
                    )
                );
            } else {
                if (action.payload.pageData.currentPage === 0) {
                    newDataState = update(state.activeTasks.data, {
                        $set: action.payload.data,
                    });
                } else {
                    newDataState = uniqWith(
                        concat(state.activeTasks.data, action.payload.data),
                        isEqual
                    );
                }
            }

            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.activeTasks.errorMessages,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.GET_TASKS_ERROR: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    loading: false,
                    data: initialState.activeTasks.data,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_FILTERS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    filters: action.payload,
                },
            });
            
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_SORT_BY_AND_STATUS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                    taskStatus: action.payload.taskStatus,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASKS_TABLE_FILTER_STATUS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    taskTableFilter: action.payload,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_ALL_TABLE_FILTERS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    filters: initialState.activeTasks.filters,
                    sortBy: initialState.activeTasks.sortBy,
                    sortAscending: initialState.activeTasks.sortAscending,
                    taskStatus: initialState.activeTasks.taskStatus,
                    taskTableFilter: initialState.activeTasks.taskTableFilter,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.CLEAR_TASKS_STATE_DATA: {
            return {
                ...state,
                activeTasks: { ...initialState.activeTasks },
            };
        }
        case TasksActionTypes.GET_TASKS_ACTION_FILTER_OPTIONS_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    actionFilterOptionsLoading: true,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.GET_TASKS_ACTION_FILTER_OPTIONS_RESPONSE: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    actionFilterOptionsLoading: false,
                    actionFilterOptions: action.payload,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    compactViewLoading: true,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_SUCCESS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    compactViewLoading: false,
                    compactView: action.payload,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    compactViewLoading: true,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_SUCCESS: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    compactViewLoading: false,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.SET_TASK_EXPAND_STATUS: {
            let newExpandedIds: any[] = [];
            if (action.payload) {
                newExpandedIds = !includes(
                    state.activeTasks.toggledExpandStatusIds,
                    action.payload
                )
                    ? [
                          ...state.activeTasks.toggledExpandStatusIds,
                          action.payload,
                      ]
                    : remove(
                          state.activeTasks.toggledExpandStatusIds,
                          (taskId: string) => taskId !== action.payload
                      );
            }

            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    toggledExpandStatusIds: newExpandedIds,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        //Single Record
        case TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $merge: {
                        selectedId:
                            initialState.activeTasks.activeData.selectedId,
                    },
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.SET_TASK_SELECTED_ID_SUCCESS: {
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_DATA_FOR_ORGANISATION_REQUEST:
        case TasksActionTypes.GET_TASK_DATA_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $merge: {
                        record: initialState.activeTasks.activeData.record,
                        loading: true,
                    },
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_DATA_SUCCESS: {
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.activeTasks.activeData.errorMessages,
                    },
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_DATA_ERROR: {
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $merge: {
                        record: initialState.activeTasks.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.CLEAR_TASK_DATA_SUCCESS: {
            const { activeData } = initialState.activeTasks;
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: activeData,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    notifyCustomersLoading: true,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.NOTIFY_ORGANISATION_CUSTOMERS_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    notifyCustomersLoading: true,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.NOTIFY_CUSTOMERS_RESPONSE: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    notifyCustomersLoading: false,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        /**
         * Invoice list here
         */
        case TasksActionTypes.GET_TASK_TICKET_INVOICES_REQUEST: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: { $set: newTaskActiveDataState },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_TICKET_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.activeTasks.activeData.invoices.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeTasks.activeData.invoices.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeTasks.activeData.invoices
                                .errorMessages,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_TICKET_INVOICES_ERROR: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeTasks.activeData.invoices.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_FILTERS: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_TABLE_FILTERS: {
            const newInvoicesState = update(state.activeTasks.activeData.invoices, {
                $merge: {
                    invoiceState: action.payload,
                },
            });

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(
                state.activeTasks.activeData.invoices,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                        invoiceState: action.payload.invoiceState,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        // For schedule list reducers
        case TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_REQUEST: {
            const newScheduleState = update(
                state.activeTasks.activeData.schedule,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        schedule: newScheduleState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: { $set: newTaskActiveDataState },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.activeTasks.activeData.schedule.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = uniqWith(
                    concat(
                        state.activeTasks.activeData.schedule.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newScheduleState = update(
                state.activeTasks.activeData.schedule,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.activeTasks.activeData.schedule
                                .errorMessages,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        schedule: newScheduleState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_ERROR: {
            const newScheduleState = update(
                state.activeTasks.activeData.schedule,
                {
                    $merge: {
                        loading: false,
                        data: initialState.activeTasks.activeData.schedule.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        schedule: newScheduleState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_FILTERS: {
            const newScheduleState = update(
                state.activeTasks.activeData.schedule,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        schedule: newScheduleState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_SORT_BY_AND_STATE: {
            const newScheduleState = update(
                state.activeTasks.activeData.schedule,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newTaskActiveDataState = update(
                state.activeTasks.activeData,
                {
                    $merge: {
                        schedule: newScheduleState,
                    },
                }
            );
            const newActiveTasks = update(state.activeTasks, {
                activeData: {
                    $set: newTaskActiveDataState,
                },
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        /**
         * Task History section
         */

        case TasksActionTypes.GET_TASK_HISTORY_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    loading: true,
                },
            });
            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.GET_TASK_HISTORY_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(state.taskHistory.data, {
                    $set: action.payload.data,
                });
            } else {
                // update(state.data, {
                //     $push: action.payload.data,
                // });
                newDataState = uniqWith(
                    concat(state.taskHistory.data, action.payload.data),
                    isEqual
                );
            }

            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    loading: false,
                    data: newDataState,
                    pageData: action.payload.pageData,
                    errorMessages: initialState.taskHistory.errorMessages,
                },
            });
            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.GET_TASK_HISTORY_ERROR: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    loading: false,
                    data: initialState.taskHistory.data,
                    errorMessages: action.payload,
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.UPDATE_TASK_HISTORY_FILTERS: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    filters: action.payload,
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.UPDATE_TASK_HISTORY_SORT_BY: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    sortBy: action.payload.sortBy,
                    sortAscending: action.payload.sortAscending,
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.UPDATE_TASK_HISTORY_TABLE_FILTER_STATUS: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    taskTableFilter: action.payload,
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.CLEAR_TASK_HISTORY_STATE_ALL_TABLE_FILTERS: {
            const newTaskHistory = update(state.taskHistory, {
                $merge: {
                    filters: initialState.taskHistory.filters,
                    sortBy: initialState.taskHistory.sortBy,
                    sortAscending: initialState.taskHistory.sortAscending,
                    taskTableFilter: initialState.taskHistory.taskTableFilter,
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.CLEAR_TASK_HISTORY_STATE_DATA: {
            return {
                ...state,
                taskHistory: { ...initialState.taskHistory },
            };
        }

        //Single Record
        case TasksActionTypes.SET_TASK_HISTORY_SELECTED_ID_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        selectedId:
                            initialState.taskHistory.activeData.selectedId,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.SET_TASK_HISTORY_SELECTED_ID_SUCCESS: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        selectedId: action.payload,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DATA_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        record: initialState.taskHistory.activeData.record,
                        loading: true,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DATA_SUCCESS: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.taskHistory.activeData.errorMessages,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DATA_ERROR: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        record: initialState.taskHistory.activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.CLEAR_TASK_HISTORY_DATA_SUCCESS: {
            const { activeData } = initialState.taskHistory;
            const newTaskHistory = update(state.taskHistory, {
                activeData: { $set: activeData },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_ACTIONED_TICKET_DETAILS_REQUEST:
        case TasksActionTypes.GET_ACTIONED_PAYMENT_DETAILS_REQUEST:
        case TasksActionTypes.GET_ACTIONED_PHONE_CALL_DATA_REQUEST: 
        case TasksActionTypes.GET_ACTIONED_PAYMENT_PLAN_DETAILS_REQUEST: 
        case TasksActionTypes.GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    $merge: {
                        record: initialState.taskHistory.activeData.record,
                        loading: true,
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // For notifications list reducers
        case TasksActionTypes.SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $merge: {
                                selectedId:
                                    initialState.taskHistory.activeData
                                        .notifications.activeData.selectedId,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_SUCCESS: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $merge: {
                                selectedId: action.payload,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_REQUEST: {
            const newNotificationsState = update(
                state.taskHistory.activeData.notifications,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: { $set: newNotificationsState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_SUCCESS: {
            let newNotificationsDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newNotificationsDataState = update(
                    state.taskHistory.activeData.notifications.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newNotificationsDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.notifications.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newNotificationsState = update(
                state.taskHistory.activeData.notifications,
                {
                    $merge: {
                        loading: false,
                        data: newNotificationsDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.notifications
                                .errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: { $set: newNotificationsState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_ERROR: {
            const newNotificationsState = update(
                state.taskHistory.activeData.notifications,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.notifications
                            .data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: { $set: newNotificationsState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATIONS_FILTERS: {
            const newNotificationsState = update(
                state.taskHistory.activeData.notifications,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: { $set: newNotificationsState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_DELIVERY_REPORT_SORT_BY: {
            const newDeliveryReportState = update(
                state.taskHistory.activeData.detailsDelivery,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    detailsDelivery: { $set: newDeliveryReportState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_REQUEST: {
            const newDeliveryReportState = update(
                state.taskHistory.activeData.detailsDelivery,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    detailsDelivery: { $set: newDeliveryReportState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_DELIVERY_REPORT_FILTERS: {
            const newDeliveryReportState = update(
                state.taskHistory.activeData.detailsDelivery,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    detailsDelivery: { $set: newDeliveryReportState },
                },
            });
            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_ERROR: {
            const newDeliveryReportState = update(
                state.taskHistory.activeData.detailsDelivery,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.detailsDelivery
                            .data,
                        //errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    detailsDelivery: { $set: newDeliveryReportState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_SUCCESS: {
            let newDeliveryReportDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDeliveryReportDataState = update(
                    state.taskHistory.activeData.detailsDelivery.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDeliveryReportDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.detailsDelivery.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newDeliveryReportState = update(
                state.taskHistory.activeData.detailsDelivery,
                {
                    $merge: {
                        loading: false,
                        data: newDeliveryReportDataState,
                        pageData: action.payload.pageData,
                        // errorMessages:
                        //     initialState.taskHistory.activeData.detailsDelivery
                        //         .errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    detailsDelivery: { $set: newDeliveryReportState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATIONS_SORT_BY: {
            const newNotificationsState = update(
                state.taskHistory.activeData.notifications,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: { $set: newNotificationsState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_REQUEST: {
            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData
                            .notifications.activeData.record,
                        loading: true,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS: {
            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.taskHistory.activeData.notifications
                                .activeData.errorMessages,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_ERROR: {
            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData
                            .notifications.activeData.record,
                        loading: false,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.CLEAR_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS: {
            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $set: initialState.taskHistory.activeData.notifications
                        .activeData,
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // For invoices list reducers
        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST: {
            const newInvoicesState = update(
                state.taskHistory.activeData.notifications.activeData.invoices,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.taskHistory.activeData.notifications.activeData
                        .invoices.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.notifications.activeData
                            .invoices.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newInvoicesState = update(
                state.taskHistory.activeData.notifications.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.notifications
                                .activeData.invoices.errorMessages,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_ERROR: {
            const newInvoicesState = update(
                state.taskHistory.activeData.notifications.activeData.invoices,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.notifications
                            .activeData.invoices.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_FILTERS: {
            const newInvoicesState = update(
                state.taskHistory.activeData.notifications.activeData.invoices,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_SORT_BY_AND_STATE: {
            const newInvoicesState = update(
                state.taskHistory.activeData.notifications.activeData.invoices,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                        invoiceState: action.payload.invoiceState,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        invoices: newInvoicesState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // For tasks list reducers
        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_REQUEST: {
            const newTasksState = update(
                state.taskHistory.activeData.notifications.activeData.tasks,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        tasks: newTasksState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_SUCCESS: {
            let newDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newDataState = update(
                    state.taskHistory.activeData.notifications.activeData.tasks
                        .data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.notifications.activeData
                            .tasks.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newTasksState = update(
                state.taskHistory.activeData.notifications.activeData.tasks,
                {
                    $merge: {
                        loading: false,
                        data: newDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.notifications
                                .activeData.tasks.errorMessages,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        tasks: newTasksState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_ERROR: {
            const newTasksState = update(
                state.taskHistory.activeData.notifications.activeData.tasks,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.notifications
                            .activeData.tasks.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        tasks: newTasksState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_FILTERS: {
            const newTasksState = update(
                state.taskHistory.activeData.notifications.activeData.tasks,
                {
                    $merge: {
                        filters: action.payload,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        tasks: newTasksState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }
        case TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_SORT_BY: {
            const newTasksState = update(
                state.taskHistory.activeData.notifications.activeData.tasks,
                {
                    $merge: {
                        sortBy: action.payload.sortBy,
                        sortAscending: action.payload.sortAscending,
                    },
                }
            );

            const newNotificationActiveDataState = update(
                state.taskHistory.activeData.notifications.activeData,
                {
                    $merge: {
                        tasks: newTasksState,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    notifications: {
                        activeData: {
                            $set: newNotificationActiveDataState,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Task history actioned tasks
        case TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_REQUEST:
        // Task history changed tasks
        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_REQUEST: {
            const newChangedTasksListState = update(
                state.taskHistory.activeData.changedTasks.list,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        list: { $set: newChangedTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_SUCCESS: {
            let newChangedTasksListDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newChangedTasksListDataState = update(
                    state.taskHistory.activeData.changedTasks.list.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newChangedTasksListDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.changedTasks.list.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newChangedTasksListState = update(
                state.taskHistory.activeData.changedTasks.list,
                {
                    $merge: {
                        loading: false,
                        data: newChangedTasksListDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.changedTasks
                                .list.errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        list: { $set: newChangedTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_ERROR: {
            const newChangedTasksListState = update(
                state.taskHistory.activeData.changedTasks.list,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.changedTasks
                            .list.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        list: { $set: newChangedTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Task history tasks failed
        case TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_REQUEST: {
            const newTaskHistoryTasksFailedListState = update(
                state.taskHistory.activeData.failedList,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    failedList: { $set: newTaskHistoryTasksFailedListState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_SUCCESS: {
            let newTaskHistoryTasksFailedListDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newTaskHistoryTasksFailedListDataState = update(
                    state.taskHistory.activeData.failedList.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newTaskHistoryTasksFailedListDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.failedList.data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newTaskHistoryTasksFailedListState = update(
                state.taskHistory.activeData.failedList,
                {
                    $merge: {
                        loading: false,
                        data: newTaskHistoryTasksFailedListDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.failedList
                                .errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    failedList: { $set: newTaskHistoryTasksFailedListState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_ERROR: {
            const newTaskHistoryTasksFailedListState = update(
                state.taskHistory.activeData.failedList,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.failedList
                            .data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    failedList: { $set: newTaskHistoryTasksFailedListState },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Actioned Tasks details
        case TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_DATA_REQUEST: 
        // Changed Tasks details
        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_REQUEST: {
            const newTaskHistoryChangedTasksActiveData = update(
                state.taskHistory.activeData.changedTasks.activeData,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        activeData: {
                            $set: newTaskHistoryChangedTasksActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_SUCCESS: {
            const newTaskHistoryChangedTasksActiveData = update(
                state.taskHistory.activeData.changedTasks.activeData,
                {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.taskHistory.activeData.changedTasks
                                .activeData.errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        activeData: {
                            $set: newTaskHistoryChangedTasksActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_ERROR: {
            const newTaskHistoryChangedTasksActiveData = update(
                state.taskHistory.activeData.changedTasks.activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData.changedTasks
                            .activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    changedTasks: {
                        activeData: {
                            $set: newTaskHistoryChangedTasksActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Edit tasks
        case TasksActionTypes.BULK_SAVE_TASK_DETAILS_REQUEST: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    saveTaskDataLoading: true,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }
        case TasksActionTypes.BULK_SAVE_TASK_DETAILS_RESPONSE: {
            const newActiveTasks = update(state.activeTasks, {
                $merge: {
                    saveTaskDataLoading: false,
                },
            });
            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        // Collections
        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_REQUEST: {
            const newTaskHistoryCollectionsActiveData = update(
                state.taskHistory.activeData.collections.activeData,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        activeData: {
                            $set: newTaskHistoryCollectionsActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_SUCCESS: {
            const newTaskHistoryCollectionsActiveData = update(
                state.taskHistory.activeData.collections.activeData,
                {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.taskHistory.activeData.collections
                                .activeData.errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        activeData: {
                            $set: newTaskHistoryCollectionsActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_ERROR: {
            const newTaskHistoryCollectionsActiveData = update(
                state.taskHistory.activeData.collections.activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData.collections
                            .activeData.record,
                        loading: false,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        activeData: {
                            $set: newTaskHistoryCollectionsActiveData,
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Collections Actioned tasks
        case TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_REQUEST: {
            const newCollectionTasksListState = update(
                state.taskHistory.activeData.collections.actionedTasks,
                {
                    $merge: {
                        loading: true,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: { $set: newCollectionTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_SUCCESS: {
            let newCollectionTasksListDataState = [];
            if (action.payload.pageData.currentPage === 0) {
                newCollectionTasksListDataState = update(
                    state.taskHistory.activeData.collections.actionedTasks.data,
                    {
                        $set: action.payload.data,
                    }
                );
            } else {
                newCollectionTasksListDataState = uniqWith(
                    concat(
                        state.taskHistory.activeData.collections.actionedTasks
                            .data,
                        action.payload.data
                    ),
                    isEqual
                );
            }

            const newCollectionTasksListState = update(
                state.taskHistory.activeData.collections.actionedTasks,
                {
                    $merge: {
                        loading: false,
                        data: newCollectionTasksListDataState,
                        pageData: action.payload.pageData,
                        errorMessages:
                            initialState.taskHistory.activeData.collections
                                .actionedTasks.errorMessages,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: { $set: newCollectionTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_ERROR: {
            const newCollectionTasksListState = update(
                state.taskHistory.activeData.collections.actionedTasks,
                {
                    $merge: {
                        loading: false,
                        data: initialState.taskHistory.activeData.collections
                            .actionedTasks.data,
                        errorMessages: action.payload,
                    },
                }
            );

            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: { $set: newCollectionTasksListState },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Collections Actioned Task data
        case TasksActionTypes.SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_REQUEST: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $merge: {
                                    selectedId:
                                        initialState.taskHistory.activeData
                                            .collections.actionedTasks
                                            .activeData.selectedId,
                                },
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_SUCCESS: {
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $merge: {
                                    selectedId: action.payload,
                                },
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_REQUEST: {
            const newCollectionsTaskActiveDataState = update(
                state.taskHistory.activeData.collections.actionedTasks
                    .activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData.collections
                            .actionedTasks.activeData.record,
                        loading: true,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $set: newCollectionsTaskActiveDataState,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS: {
            const newCollectionsTaskActiveDataState = update(
                state.taskHistory.activeData.collections.actionedTasks
                    .activeData,
                {
                    $merge: {
                        record: action.payload.record,
                        loading: false,
                        errorMessages:
                            initialState.taskHistory.activeData.collections
                                .actionedTasks.activeData.errorMessages,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $set: newCollectionsTaskActiveDataState,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        case TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_ERROR: {
            const newCollectionsTaskActiveDataState = update(
                state.taskHistory.activeData.collections.actionedTasks
                    .activeData,
                {
                    $merge: {
                        record: initialState.taskHistory.activeData.collections
                            .actionedTasks.activeData.record,
                        loading: false,
                    },
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $set: newCollectionsTaskActiveDataState,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Collections clear data
        case TasksActionTypes.CLEAR_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS: {
            const newCollectionsTaskActiveDataState = update(
                state.taskHistory.activeData.collections.actionedTasks
                    .activeData,
                {
                    $set: initialState.taskHistory.activeData.collections
                        .actionedTasks.activeData,
                }
            );
            const newTaskHistory = update(state.taskHistory, {
                activeData: {
                    collections: {
                        actionedTasks: {
                            activeData: {
                                $set: newCollectionsTaskActiveDataState,
                            },
                        },
                    },
                },
            });

            return {
                ...state,
                taskHistory: newTaskHistory,
            };
        }

        // Task viewing
        case TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_SUCCESS: {
            const newActiveTasks = update(state.activeTasks, {
                viewingUser: {
                    $set: {
                        loading: false,
                        user: action.payload
                    },
                }
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }

        case TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_ERROR: {
            const newActiveTasks = update(state.activeTasks, {
                viewingUser: {
                    $set: {
                        loading: false,
                        user: undefined
                    },
                }
            });

            return {
                ...state,
                activeTasks: newActiveTasks,
            };
        }


        case TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION_REQUEST:
        case TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_REQUEST: {
            if (!action.payload.silent) {
                const newActiveTasks = update(state.activeTasks, {
                    viewingUser: {
                        loading: {
                            $set: true
                        },
                    }
                });

                return {
                    ...state,
                    activeTasks: newActiveTasks,
                };
            }

            return state;
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as tasksReducer };
